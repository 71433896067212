<template>
  <div class="roadmap">
    <div class="text-box">
      <div class="title" style="width:325px">
        <p>On-Chain</p>
        <p>Culture</p>
        <div class="line"></div>
      </div>
      <div class="sub-title">
        <p>Bring 1B people on chain </p>
        <p>through culture & </p>
        <p>digital assets</p>
      </div>
    </div>
    <div class="text-box">
      <div class="title">
        <p>Open-World</p>
        <p>Metaverse</p>
        <div class="line"></div>
      </div>
      <div class="sub-title">
        <p>Support open-world, </p>
        <p>cross-platform assets </p>
        <p>& protocols</p>
      </div>
    </div>
    <div class="text-box">
      <div class="title" style="width:395px">
        <p>IP</p>
        <p>ecosystem</p>
        <div class="line"></div>
      </div>
      <div class="sub-title">
        <p>Boost IP ecosystem through finance,</p>
        <p>technology, & media</p>
      </div>
    </div>
    <div class="roadmap-footer"></div>
  </div>
</template>

<script>
export default {
  name: 'Roadmap',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.roadmap {
  position: relative;
  padding: 310px 40px 150px 120px;
  color: #000;
  font-size: 34.64px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}
.line {
  width: 325px;
  border-bottom: 2px solid #000;
}
.text-box {
  margin-bottom: 145px;
}
.text-box .title {
  color: #000;
  font-size: 57.15px;
  font-weight: 400;
  margin-bottom: 60px;
}
.text-box .sub-title {
  color: #000;
  font-size: 27.31px;
  font-weight: 400;

}
.roadmap-footer {
  position: absolute;
  bottom: 0;
  left: 323px;
  width: 100%;
  height: 100px;
  background-image: url("~@/assets/images/footer-img.png");
  background-position: bottom 0px left 0px;
  background-size: 500px 91px;
  background-repeat: repeat no-repeat;
}
</style>
